import * as React from 'react'
import NumberSliderWithTooltip, { createSliderWithTooltip } from 'rc-slider'
import './rc-slider.scss'

const NumberSlider = ({ data, formValues }) => {
    const SliderWithTooltip = createSliderWithTooltip(NumberSliderWithTooltip)

    const identifier = data.identifier

    const onSliderChange = (value) => {
        // Update the hidden input
        data.input.current.value = value

        // Make sure the form data gets updated
        if (formValues) {
            formValues[identifier] = value
        }
    }

    const itemSlider = {
        min: 0,
        max: data.max,
        defaultValue: data.default,
        onChange: onSliderChange,
        tipProps: { visible: true },
        tipFormatter: (value) => `£${value.toLocaleString()}`
    }

    return <SliderWithTooltip {...itemSlider} />
}

export default NumberSlider
